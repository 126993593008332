import React from 'react';
import Helmet from 'react-helmet';
import { findIndex, isMatch, uniqBy } from 'lodash';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Section from '@nib-components/section';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import Container from '@nib-components/container';
import Card from '@nib/card';
import Link from '@nib-components/link';
import colors from '@nib-components/colors';
import { Stack, Tiles } from '@nib/layout';

import Layout from '../components/Layout';
import metrics from '../metrics';
import { ContentfulBlogArticleNode, ContentfulBlogArticleSection } from '../constructs/models';
import { compareDateString, getFormattedDateFromDateString } from '../utils/moment-utils';

const title = 'Free Resources';
const metaDescription = 'Find all our free resources and blog posts in one page here.';

export const freeResourcesPageQuery = graphql`
  query FreeResourcesPageQuery {
    allContentfulBlogArticle {
      edges {
        node {
          title
          urlSlug
          startDate
          heroType
          keywords
          section {
            title
            id
            order
          }
          summary {
            summary
          }
          thumbnail {
            title
            gatsbyImageData(layout: FULL_WIDTH)
            file {
              url
            }
          }
          heroImage {
            title
            gatsbyImageData(layout: FULL_WIDTH)
            file {
              url
            }
          }
        }
      }
    }
  }
`;

interface GraphQlBlogArticle {
  allContentfulBlogArticle: ContentfulBlogArticle;
}

interface ContentfulBlogArticle {
  edges: ContentfulBlogArticleEdgesShape[];
}

interface ContentfulBlogArticleEdgesShape {
  node: ContentfulBlogArticleNode;
}

interface FreeResourcesProps {
  data: GraphQlBlogArticle;
}

const FreeResources = (props: FreeResourcesProps): JSX.Element => {
  const { data } = props;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/free-resources',
    url: 'https://www.nib.co.nz/free-resources',
    description: 'Great value health insurance',
    name: ['Free resources | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={metaDescription} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <Section>
        <Stack space={{ xs: 4, md: 5, lg: 7 }}>
          <HeaderSection />
          <ArticlesSection articles={data.allContentfulBlogArticle} />
        </Stack>
      </Section>
    </Layout>
  );
};

const HeaderSection = (): JSX.Element => {
  return (
    <Container role="section">
      <Heading size={{ sm: 2, lg: 1 }} component="h1">
        Free resources
      </Heading>
    </Container>
  );
};

interface ArticleSectionProps {
  articles: ContentfulBlogArticle;
}

const ArticlesSection = (props: ArticleSectionProps): JSX.Element => {
  const { articles } = props;

  const sectionInfo = getSectionInfo(articles.edges).sort((a, b) => a.order - b.order);

  return (
    <Container role="section">
      <Stack space={7}>
        {sectionInfo.map((info, index) => (
          <NewsSection articles={articles.edges} sectionInfo={info} key={index} />
        ))}
      </Stack>
    </Container>
  );
};

const getSectionInfo = (
  articlesArr: ContentfulBlogArticleEdgesShape[]
): ContentfulBlogArticleSection[] => {
  return uniqBy(articlesArr, (article) => article.node.section.id).map((article) => {
    return article.node.section;
  });
};

const getFeaturedArticle = (
  articles: ContentfulBlogArticleEdgesShape[]
): ContentfulBlogArticleEdgesShape | undefined => {
  const sortedFeaturedArticles = articles.sort((a, b) =>
    compareDateString(a.node.startDate, b.node.startDate)
  );

  // From Contentful:
  // Note: the most recent article with "Show as Hero Article" turned on will be displayed as the hero
  return sortedFeaturedArticles.length ? sortedFeaturedArticles[0] : undefined;
};

interface NewsSectionProps {
  articles: ContentfulBlogArticleEdgesShape[];
  sectionInfo: ContentfulBlogArticleSection;
}

const NewsSection = (props: NewsSectionProps): JSX.Element => {
  const { articles, sectionInfo } = props;

  const articlesWithinSection = articles.filter(
    (article) => article.node.section.id === sectionInfo.id
  );

  const featuredArticle = getFeaturedArticle(articlesWithinSection);
  if (featuredArticle) {
    const featuredArticleIndex = findIndex(articlesWithinSection, (article) =>
      isMatch(article.node, featuredArticle.node)
    );
    articlesWithinSection.splice(featuredArticleIndex, 1); // Remove featured article from articles list
  }

  return (
    <Stack space={4}>
      {featuredArticle && <FeatureArticle featuredArticle={featuredArticle.node} />}
      {/*
      From Contentful: Note: the most recent article with "Show as Hero Article" turned on will be displayed as the hero,
      so they will need to display all published artciles within the section
      */}
      <NewsList articles={articlesWithinSection} sectionTitle={sectionInfo.title} />
    </Stack>
  );
};

interface FeatureArticleProps {
  featuredArticle: ContentfulBlogArticleNode;
}

const FeatureArticle = (props: FeatureArticleProps): JSX.Element => {
  const { featuredArticle } = props;

  const { title, thumbnail, summary, startDate, heroImage, urlSlug } = featuredArticle;

  return (
    <Container>
      <Card
        collapseBelow="md"
        horizontalLayout={true}
        image={
          (heroImage && heroImage.gatsbyImageData.images.fallback.src) ||
          thumbnail.gatsbyImageData.images.fallback.src
        }
        imageAlt={(heroImage && heroImage.title) || thumbnail.title}
      >
        <Card.Content title={title} titleSize={2} titleComponent="h2">
          <Stack space={4}>
            <Stack space={1}>
              <Copy small={true} color={colors.grumpy}>
                {getFormattedDateFromDateString(startDate)}
              </Copy>
              <Copy>{summary.summary}</Copy>
            </Stack>
            <Link href={`/free-resources/article/${urlSlug}`}>Read more</Link>
          </Stack>
        </Card.Content>
      </Card>
    </Container>
  );
};

interface NewsListProps {
  articles: ContentfulBlogArticleEdgesShape[];
  sectionTitle: string;
}

const NewsList = (props: NewsListProps): JSX.Element | null => {
  const { articles, sectionTitle } = props;

  if (!articles.length) {
    return null;
  }

  const sortedArticles = articles.sort((a, b) =>
    compareDateString(a.node.startDate, b.node.startDate)
  );

  const sectionId = sectionTitle.trim().replace(/\s/g, '_').toLowerCase();

  return (
    <Stack space={4}>
      <Heading id={sectionId} size={2}>
        {sectionTitle}
      </Heading>
      {/* TODO: Change the tiles component to support flexible cards */}
      <Tiles space={4} columns={{ sm: 1, md: 2, lg: 3 }}>
        {sortedArticles.map((article, index) => (
          <NewsCard cardDetails={article.node} key={index} />
        ))}
      </Tiles>
    </Stack>
  );
};

const BackgroundImg = styled.img`
  height: 275px;
  width: 100%;
  background-image: ${(props) => `url('${props.backgroundImage}')`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${colors.sneezy};

  ${breakpoint('lg')`
    height: 200px;
  `};

  ${breakpoint('xl')`
    height: 250px;
  `};

  ${breakpoint('xxl')`
    height: 300px;
  `};
`;

interface NewsCardProps {
  cardDetails: ContentfulBlogArticleNode;
}

const NewsCard = (props: NewsCardProps): JSX.Element => {
  const { cardDetails } = props;

  const { thumbnail, startDate, summary, title, urlSlug } = cardDetails;

  // TODO: Change the Card component so that the `Read more` links are
  // inline with the adjacent cards
  return (
    <Card
      withBoxShadow={true}
      height="100%"
      image={<BackgroundImg backgroundImage={thumbnail?.gatsbyImageData.images.fallback.src} />}
      imageAlt={thumbnail?.title}
    >
      <Card.Content title={title}>
        <Stack space={4}>
          <Stack space={1}>
            <Copy small={true} color={colors.grumpy}>
              {getFormattedDateFromDateString(startDate)}
            </Copy>
            <Copy>{summary.summary}</Copy>
          </Stack>
          <Link href={`/free-resources/article/${urlSlug}`}>Read more</Link>
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default metrics({ pageName: 'free-resources' })(FreeResources);
